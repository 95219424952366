export enum CountryCode {
  US = "US",
  CA = "CA",
  NORTHAMERICA = "NORTHAMERICA",
}

export enum TypePhoneNumber {
  LOCAL = "LOCAL",
  TOLL_FREE = "TOLL_FREE"
}

interface MapsProps {
  country: CountryCode;
  TypePhoneNumber: TypePhoneNumber;
  regions: RegionsProps[];
}

export interface RegionsProps {
  npa: string;
  state: string;
  label: string;
}


export const mapRegions: MapsProps[] = [
  { 
    country: CountryCode.US, 
    TypePhoneNumber: TypePhoneNumber.LOCAL,
    regions: [
      { npa: "907", state: "AK", label: "Alaska" },
      { npa: "205", state: "AL", label: "Alabama" },
      { npa: "251", state: "AL", label: "Alabama" },
      { npa: "256", state: "AL", label: "Alabama" },
      { npa: "334", state: "AL", label: "Alabama" },
      { npa: "659", state: "AL", label: "Alabama" },
      { npa: "938", state: "AL", label: "Alabama" },
      { npa: "327", state: "AR", label: "Arkansas" },
      { npa: "479", state: "AR", label: "Arkansas" },
      { npa: "501", state: "AR", label: "Arkansas" },
      { npa: "870", state: "AR", label: "Arkansas" },
      { npa: "480", state: "AZ", label: "Arizona" },
      { npa: "520", state: "AZ", label: "Arizona" },
      { npa: "602", state: "AZ", label: "Arizona" },
      { npa: "623", state: "AZ", label: "Arizona" },
      { npa: "928", state: "AZ", label: "Arizona" },
      { npa: "209", state: "CA", label: "California" },
      { npa: "213", state: "CA", label: "California" },
      { npa: "279", state: "CA", label: "California" },
      { npa: "310", state: "CA", label: "California" },
      { npa: "323", state: "CA", label: "California" },
      { npa: "341", state: "CA", label: "California" },
      { npa: "350", state: "CA", label: "California" },
      { npa: "369", state: "CA", label: "California" },
      { npa: "408", state: "CA", label: "California" },
      { npa: "415", state: "CA", label: "California" },
      { npa: "424", state: "CA", label: "California" },
      { npa: "442", state: "CA", label: "California" },
      { npa: "510", state: "CA", label: "California" },
      { npa: "530", state: "CA", label: "California" },
      { npa: "559", state: "CA", label: "California" },
      { npa: "562", state: "CA", label: "California" },
      { npa: "619", state: "CA", label: "California" },
      { npa: "626", state: "CA", label: "California" },
      { npa: "628", state: "CA", label: "California" },
      { npa: "650", state: "CA", label: "California" },
      { npa: "657", state: "CA", label: "California" },
      { npa: "661", state: "CA", label: "California" },
      { npa: "669", state: "CA", label: "California" },
      { npa: "707", state: "CA", label: "California" },
      { npa: "714", state: "CA", label: "California" },
      { npa: "747", state: "CA", label: "California" },
      { npa: "760", state: "CA", label: "California" },
      { npa: "805", state: "CA", label: "California" },
      { npa: "818", state: "CA", label: "California" },
      { npa: "820", state: "CA", label: "California" },
      { npa: "831", state: "CA", label: "California" },
      { npa: "840", state: "CA", label: "California" },
      { npa: "858", state: "CA", label: "California" },
      { npa: "909", state: "CA", label: "California" },
      { npa: "916", state: "CA", label: "California" },
      { npa: "925", state: "CA", label: "California" },
      { npa: "949", state: "CA", label: "California" },
      { npa: "951", state: "CA", label: "California" },
      { npa: "303", state: "CO", label: "Colorado" },
      { npa: "719", state: "CO", label: "Colorado" },
      { npa: "720", state: "CO", label: "Colorado" },
      { npa: "970", state: "CO", label: "Colorado" },
      { npa: "983", state: "CO", label: "Colorado" },
      { npa: "203", state: "CT", label: "Connecticut" },
      { npa: "475", state: "CT", label: "Connecticut" },
      { npa: "860", state: "CT", label: "Connecticut" },
      { npa: "959", state: "CT", label: "Connecticut" },
      { npa: "202", state: "DC", label: "Washington D.C" },
      { npa: "771", state: "DC", label: "Washington D.C" },
      { npa: "302", state: "DE", label: "Delaware" },
      { npa: "239", state: "FL", label: "Florida" },
      { npa: "305", state: "FL", label: "Florida" },
      { npa: "321", state: "FL", label: "Florida" },
      { npa: "324", state: "FL", label: "Florida" },
      { npa: "352", state: "FL", label: "Florida" },
      { npa: "386", state: "FL", label: "Florida" },
      { npa: "407", state: "FL", label: "Florida" },
      { npa: "448", state: "FL", label: "Florida" },
      { npa: "561", state: "FL", label: "Florida" },
      { npa: "645", state: "FL", label: "Florida" },
      { npa: "656", state: "FL", label: "Florida" },
      { npa: "689", state: "FL", label: "Florida" },
      { npa: "727", state: "FL", label: "Florida" },
      { npa: "728", state: "FL", label: "Florida" },
      { npa: "754", state: "FL", label: "Florida" },
      { npa: "772", state: "FL", label: "Florida" },
      { npa: "786", state: "FL", label: "Florida" },
      { npa: "813", state: "FL", label: "Florida" },
      { npa: "850", state: "FL", label: "Florida" },
      { npa: "863", state: "FL", label: "Florida" },
      { npa: "904", state: "FL", label: "Florida" },
      { npa: "941", state: "FL", label: "Florida" },
      { npa: "954", state: "FL", label: "Florida" },
      { npa: "229", state: "GA", label: "Georgia" },
      { npa: "404", state: "GA", label: "Georgia" },
      { npa: "470", state: "GA", label: "Georgia" },
      { npa: "478", state: "GA", label: "Georgia" },
      { npa: "678", state: "GA", label: "Georgia" },
      { npa: "706", state: "GA", label: "Georgia" },
      { npa: "762", state: "GA", label: "Georgia" },
      { npa: "770", state: "GA", label: "Georgia" },
      { npa: "912", state: "GA", label: "Georgia" },
      { npa: "943", state: "GA", label: "Georgia" },
      { npa: "808", state: "HI", label: "Hawaii" },
      { npa: "319", state: "IA", label: "Iowa" },
      { npa: "515", state: "IA", label: "Iowa" },
      { npa: "563", state: "IA", label: "Iowa" },
      { npa: "641", state: "IA", label: "Iowa" },
      { npa: "712", state: "IA", label: "Iowa" },
      { npa: "208", state: "ID", label: "Idaho" },
      { npa: "986", state: "ID", label: "Idaho" },
      { npa: "217", state: "IL", label: "Illinois" },
      { npa: "224", state: "IL", label: "Illinois" },
      { npa: "309", state: "IL", label: "Illinois" },
      { npa: "312", state: "IL", label: "Illinois" },
      { npa: "331", state: "IL", label: "Illinois" },
      { npa: "447", state: "IL", label: "Illinois" },
      { npa: "464", state: "IL", label: "Illinois" },
      { npa: "618", state: "IL", label: "Illinois" },
      { npa: "630", state: "IL", label: "Illinois" },
      { npa: "708", state: "IL", label: "Illinois" },
      { npa: "730", state: "IL", label: "Illinois" },
      { npa: "773", state: "IL", label: "Illinois" },
      { npa: "779", state: "IL", label: "Illinois" },
      { npa: "815", state: "IL", label: "Illinois" },
      { npa: "847", state: "IL", label: "Illinois" },
      { npa: "861", state: "IL", label: "Illinois" },
      { npa: "872", state: "IL", label: "Illinois" },
      { npa: "219", state: "IN", label: "Indiana" },
      { npa: "260", state: "IN", label: "Indiana" },
      { npa: "317", state: "IN", label: "Indiana" },
      { npa: "463", state: "IN", label: "Indiana" },
      { npa: "574", state: "IN", label: "Indiana" },
      { npa: "765", state: "IN", label: "Indiana" },
      { npa: "812", state: "IN", label: "Indiana" },
      { npa: "930", state: "IN", label: "Indiana" },
      { npa: "316", state: "KS", label: "Kansas" },
      { npa: "620", state: "KS", label: "Kansas" },
      { npa: "785", state: "KS", label: "Kansas" },
      { npa: "913", state: "KS", label: "Kansas" },
      { npa: "270", state: "KY", label: "Kentucky" },
      { npa: "364", state: "KY", label: "Kentucky" },
      { npa: "502", state: "KY", label: "Kentucky" },
      { npa: "606", state: "KY", label: "Kentucky" },
      { npa: "859", state: "KY", label: "Kentucky" },
      { npa: "225", state: "LA", label: "Louisiana" },
      { npa: "318", state: "LA", label: "Louisiana" },
      { npa: "337", state: "LA", label: "Louisiana" },
      { npa: "504", state: "LA", label: "Louisiana" },
      { npa: "985", state: "LA", label: "Louisiana" },
      { npa: "339", state: "MA", label: "Massachusetts" },
      { npa: "351", state: "MA", label: "Massachusetts" },
      { npa: "413", state: "MA", label: "Massachusetts" },
      { npa: "508", state: "MA", label: "Massachusetts" },
      { npa: "617", state: "MA", label: "Massachusetts" },
      { npa: "774", state: "MA", label: "Massachusetts" },
      { npa: "781", state: "MA", label: "Massachusetts" },
      { npa: "857", state: "MA", label: "Massachusetts" },
      { npa: "978", state: "MA", label: "Massachusetts" },
      { npa: "227", state: "MD", label: "Maryland" },
      { npa: "240", state: "MD", label: "Maryland" },
      { npa: "301", state: "MD", label: "Maryland" },
      { npa: "410", state: "MD", label: "Maryland" },
      { npa: "443", state: "MD", label: "Maryland" },
      { npa: "667", state: "MD", label: "Maryland" },
      { npa: "207", state: "ME", label: "Maine" },
      { npa: "231", state: "MI", label: "Michigan" },
      { npa: "248", state: "MI", label: "Michigan" },
      { npa: "269", state: "MI", label: "Michigan" },
      { npa: "313", state: "MI", label: "Michigan" },
      { npa: "517", state: "MI", label: "Michigan" },
      { npa: "586", state: "MI", label: "Michigan" },
      { npa: "616", state: "MI", label: "Michigan" },
      { npa: "734", state: "MI", label: "Michigan" },
      { npa: "810", state: "MI", label: "Michigan" },
      { npa: "906", state: "MI", label: "Michigan" },
      { npa: "947", state: "MI", label: "Michigan" },
      { npa: "989", state: "MI", label: "Michigan" },
      { npa: "218", state: "MN", label: "Minnesota" },
      { npa: "320", state: "MN", label: "Minnesota" },
      { npa: "507", state: "MN", label: "Minnesota" },
      { npa: "612", state: "MN", label: "Minnesota" },
      { npa: "651", state: "MN", label: "Minnesota" },
      { npa: "763", state: "MN", label: "Minnesota" },
      { npa: "924", state: "MN", label: "Minnesota" },
      { npa: "952", state: "MN", label: "Minnesota" },
      { npa: "235", state: "MO", label: "Missouri" },
      { npa: "314", state: "MO", label: "Missouri" },
      { npa: "417", state: "MO", label: "Missouri" },
      { npa: "557", state: "MO", label: "Missouri" },
      { npa: "573", state: "MO", label: "Missouri" },
      { npa: "636", state: "MO", label: "Missouri" },
      { npa: "660", state: "MO", label: "Missouri" },
      { npa: "816", state: "MO", label: "Missouri" },
      { npa: "975", state: "MO", label: "Missouri" },
      { npa: "228", state: "MS", label: "Mississippi" },
      { npa: "601", state: "MS", label: "Mississippi" },
      { npa: "662", state: "MS", label: "Mississippi" },
      { npa: "769", state: "MS", label: "Mississippi" },
      { npa: "406", state: "MT", label: "Montana" },
      { npa: "252", state: "NC", label: "North Carolina" },
      { npa: "336", state: "NC", label: "North Carolina" },
      { npa: "472", state: "NC", label: "North Carolina" },
      { npa: "704", state: "NC", label: "North Carolina" },
      { npa: "743", state: "NC", label: "North Carolina" },
      { npa: "828", state: "NC", label: "North Carolina" },
      { npa: "910", state: "NC", label: "North Carolina" },
      { npa: "919", state: "NC", label: "North Carolina" },
      { npa: "980", state: "NC", label: "North Carolina" },
      { npa: "984", state: "NC", label: "North Carolina" },
      { npa: "701", state: "ND", label: "North Dakota" },
      { npa: "308", state: "NE", label: "Nebraska" },
      { npa: "402", state: "NE", label: "Nebraska" },
      { npa: "531", state: "NE", label: "Nebraska" },
      { npa: "603", state: "NH", label: "New Hampshire" },
      { npa: "201", state: "NJ", label: "New Jersey" },
      { npa: "551", state: "NJ", label: "New Jersey" },
      { npa: "609", state: "NJ", label: "New Jersey" },
      { npa: "640", state: "NJ", label: "New Jersey" },
      { npa: "732", state: "NJ", label: "New Jersey" },
      { npa: "848", state: "NJ", label: "New Jersey" },
      { npa: "856", state: "NJ", label: "New Jersey" },
      { npa: "862", state: "NJ", label: "New Jersey" },
      { npa: "908", state: "NJ", label: "New Jersey" },
      { npa: "973", state: "NJ", label: "New Jersey" },
      { npa: "505", state: "NM", label: "New Mexico" },
      { npa: "575", state: "NM", label: "New Mexico" },
      { npa: "702", state: "NV", label: "Nevada" },
      { npa: "725", state: "NV", label: "Nevada" },
      { npa: "775", state: "NV", label: "Nevada" },
      { npa: "212", state: "NY", label: "New York" },
      { npa: "315", state: "NY", label: "New York" },
      { npa: "329", state: "NY", label: "New York" },
      { npa: "332", state: "NY", label: "New York" },
      { npa: "347", state: "NY", label: "New York" },
      { npa: "363", state: "NY", label: "New York" },
      { npa: "516", state: "NY", label: "New York" },
      { npa: "518", state: "NY", label: "New York" },
      { npa: "585", state: "NY", label: "New York" },
      { npa: "607", state: "NY", label: "New York" },
      { npa: "624", state: "NY", label: "New York" },
      { npa: "631", state: "NY", label: "New York" },
      { npa: "646", state: "NY", label: "New York" },
      { npa: "680", state: "NY", label: "New York" },
      { npa: "716", state: "NY", label: "New York" },
      { npa: "718", state: "NY", label: "New York" },
      { npa: "838", state: "NY", label: "New York" },
      { npa: "845", state: "NY", label: "New York" },
      { npa: "914", state: "NY", label: "New York" },
      { npa: "917", state: "NY", label: "New York" },
      { npa: "929", state: "NY", label: "New York" },
      { npa: "934", state: "NY", label: "New York" },
      { npa: "216", state: "OH", label: "Ohio" },
      { npa: "220", state: "OH", label: "Ohio" },
      { npa: "234", state: "OH", label: "Ohio" },
      { npa: "283", state: "OH", label: "Ohio" },
      { npa: "326", state: "OH", label: "Ohio" },
      { npa: "330", state: "OH", label: "Ohio" },
      { npa: "380", state: "OH", label: "Ohio" },
      { npa: "419", state: "OH", label: "Ohio" },
      { npa: "436", state: "OH", label: "Ohio" },
      { npa: "440", state: "OH", label: "Ohio" },
      { npa: "513", state: "OH", label: "Ohio" },
      { npa: "567", state: "OH", label: "Ohio" },
      { npa: "614", state: "OH", label: "Ohio" },
      { npa: "740", state: "OH", label: "Ohio" },
      { npa: "937", state: "OH", label: "Ohio" },
      { npa: "405", state: "OK", label: "Oklahoma" },
      { npa: "539", state: "OK", label: "Oklahoma" },
      { npa: "572", state: "OK", label: "Oklahoma" },
      { npa: "580", state: "OK", label: "Oklahoma" },
      { npa: "918", state: "OK", label: "Oklahoma" },
      { npa: "458", state: "OR", label: "Oregon" },
      { npa: "503", state: "OR", label: "Oregon" },
      { npa: "541", state: "OR", label: "Oregon" },
      { npa: "971", state: "OR", label: "Oregon" },
      { npa: "215", state: "PA", label: "Pennsylvania" },
      { npa: "223", state: "PA", label: "Pennsylvania" },
      { npa: "267", state: "PA", label: "Pennsylvania" },
      { npa: "272", state: "PA", label: "Pennsylvania" },
      { npa: "412", state: "PA", label: "Pennsylvania" },
      { npa: "445", state: "PA", label: "Pennsylvania" },
      { npa: "484", state: "PA", label: "Pennsylvania" },
      { npa: "570", state: "PA", label: "Pennsylvania" },
      { npa: "582", state: "PA", label: "Pennsylvania" },
      { npa: "610", state: "PA", label: "Pennsylvania" },
      { npa: "717", state: "PA", label: "Pennsylvania" },
      { npa: "724", state: "PA", label: "Pennsylvania" },
      { npa: "814", state: "PA", label: "Pennsylvania" },
      { npa: "835", state: "PA", label: "Pennsylvania" },
      { npa: "878", state: "PA", label: "Pennsylvania" },
      { npa: "401", state: "RI", label: "Rhode Island" },
      { npa: "803", state: "SC", label: "South Carolina" },
      { npa: "821", state: "SC", label: "South Carolina" },
      { npa: "839", state: "SC", label: "South Carolina" },
      { npa: "843", state: "SC", label: "South Carolina" },
      { npa: "854", state: "SC", label: "South Carolina" },
      { npa: "864", state: "SC", label: "South Carolina" },
      { npa: "605", state: "SD", label: "South Dakota" },
      { npa: "423", state: "TN", label: "Tennessee" },
      { npa: "615", state: "TN", label: "Tennessee" },
      { npa: "629", state: "TN", label: "Tennessee" },
      { npa: "731", state: "TN", label: "Tennessee" },
      { npa: "865", state: "TN", label: "Tennessee" },
      { npa: "901", state: "TN", label: "Tennessee" },
      { npa: "931", state: "TN", label: "Tennessee" },
      { npa: "210", state: "TX", label: "Texas" },
      { npa: "214", state: "TX", label: "Texas" },
      { npa: "254", state: "TX", label: "Texas" },
      { npa: "281", state: "TX", label: "Texas" },
      { npa: "325", state: "TX", label: "Texas" },
      { npa: "346", state: "TX", label: "Texas" },
      { npa: "361", state: "TX", label: "Texas" },
      { npa: "409", state: "TX", label: "Texas" },
      { npa: "430", state: "TX", label: "Texas" },
      { npa: "432", state: "TX", label: "Texas" },
      { npa: "469", state: "TX", label: "Texas" },
      { npa: "512", state: "TX", label: "Texas" },
      { npa: "682", state: "TX", label: "Texas" },
      { npa: "713", state: "TX", label: "Texas" },
      { npa: "726", state: "TX", label: "Texas" },
      { npa: "737", state: "TX", label: "Texas" },
      { npa: "806", state: "TX", label: "Texas" },
      { npa: "817", state: "TX", label: "Texas" },
      { npa: "830", state: "TX", label: "Texas" },
      { npa: "832", state: "TX", label: "Texas" },
      { npa: "903", state: "TX", label: "Texas" },
      { npa: "915", state: "TX", label: "Texas" },
      { npa: "936", state: "TX", label: "Texas" },
      { npa: "940", state: "TX", label: "Texas" },
      { npa: "945", state: "TX", label: "Texas" },
      { npa: "956", state: "TX", label: "Texas" },
      { npa: "972", state: "TX", label: "Texas" },
      { npa: "979", state: "TX", label: "Texas" },
      { npa: "385", state: "UT", label: "Utah" },
      { npa: "435", state: "UT", label: "Utah" },
      { npa: "801", state: "UT", label: "Utah" },
      { npa: "276", state: "VA", label: "Virginia" },
      { npa: "434", state: "VA", label: "Virginia" },
      { npa: "540", state: "VA", label: "Virginia" },
      { npa: "571", state: "VA", label: "Virginia" },
      { npa: "686", state: "VA", label: "Virginia" },
      { npa: "703", state: "VA", label: "Virginia" },
      { npa: "757", state: "VA", label: "Virginia" },
      { npa: "804", state: "VA", label: "Virginia" },
      { npa: "826", state: "VA", label: "Virginia" },
      { npa: "948", state: "VA", label: "Virginia" },
      { npa: "802", state: "VT", label: "Vermont" },
      { npa: "206", state: "WA", label: "Washington" },
      { npa: "253", state: "WA", label: "Washington" },
      { npa: "360", state: "WA", label: "Washington" },
      { npa: "425", state: "WA", label: "Washington" },
      { npa: "509", state: "WA", label: "Washington" },
      { npa: "564", state: "WA", label: "Washington" },
      { npa: "262", state: "WI", label: "Wisconsin" },
      { npa: "274", state: "WI", label: "Wisconsin" },
      { npa: "353", state: "WI", label: "Wisconsin" },
      { npa: "414", state: "WI", label: "Wisconsin" },
      { npa: "534", state: "WI", label: "Wisconsin" },
      { npa: "608", state: "WI", label: "Wisconsin" },
      { npa: "715", state: "WI", label: "Wisconsin" },
      { npa: "920", state: "WI", label: "Wisconsin" },
      { npa: "304", state: "WV", label: "West Virginia" },
      { npa: "681", state: "WV", label: "West Virginia" },
      { npa: "307", state: "WY", label: "Wyoming" },
    ] 
  },
  { 
    country: CountryCode.CA, 
    TypePhoneNumber: TypePhoneNumber.LOCAL,
    regions: [
      { npa: "368", state: "AB", label: "Alberta" },
      { npa: "403", state: "AB", label: "Alberta" },
      { npa: "587", state: "AB", label: "Alberta" },
      { npa: "780", state: "AB", label: "Alberta" },
      { npa: "825", state: "AB", label: "Alberta" },
      { npa: "236", state: "BC", label: "British Columbia" },
      { npa: "250", state: "BC", label: "British Columbia" },
      { npa: "604", state: "BC", label: "British Columbia" },
      { npa: "672", state: "BC", label: "British Columbia" },
      { npa: "778", state: "BC", label: "British Columbia" },
      { npa: "257", state: "BC", label: "British Columbia" },
      { npa: "204", state: "MB", label: "Manitoba" },
      { npa: "431", state: "MB", label: "Manitoba" },
      { npa: "584", state: "MB", label: "Manitoba" },
      { npa: "428", state: "NB", label: "New Brunswick" },
      { npa: "506", state: "NB", label: "New Brunswick" },
      { npa: "709", state: "NL", label: "Newfoundland and Labrador" },
      { npa: "879", state: "NL", label: "Newfoundland and Labrador" },
      { npa: "782", state: "NS", label: "Nova Scotia" },
      { npa: "902", state: "NS", label: "Nova Scotia" },
      { npa: "867", state: "NT", label: "Northwest Territories" },
      { npa: "867", state: "NU", label: "Nunavut" },
      { npa: "226", state: "ON", label: "Ontario" },
      { npa: "249", state: "ON", label: "Ontario" },
      { npa: "289", state: "ON", label: "Ontario" },
      { npa: "343", state: "ON", label: "Ontario" },
      { npa: "365", state: "ON", label: "Ontario" },
      { npa: "382", state: "ON", label: "Ontario" },
      { npa: "416", state: "ON", label: "Ontario" },
      { npa: "437", state: "ON", label: "Ontario" },
      { npa: "519", state: "ON", label: "Ontario" },
      { npa: "548", state: "ON", label: "Ontario" },
      { npa: "613", state: "ON", label: "Ontario" },
      { npa: "647", state: "ON", label: "Ontario" },
      { npa: "683", state: "ON", label: "Ontario" },
      { npa: "705", state: "ON", label: "Ontario" },
      { npa: "742", state: "ON", label: "Ontario" },
      { npa: "753", state: "ON", label: "Ontario" },
      { npa: "807", state: "ON", label: "Ontario" },
      { npa: "905", state: "ON", label: "Ontario" },
      { npa: "942", state: "ON", label: "Ontario" },
      { npa: "782", state: "PE", label: "Prince Edward Island" },
      { npa: "902", state: "PE", label: "Prince Edward Island" },
      { npa: "263", state: "QC", label: "Quebec" },
      { npa: "354", state: "QC", label: "Quebec" },
      { npa: "367", state: "QC", label: "Quebec" },
      { npa: "418", state: "QC", label: "Quebec" },
      { npa: "438", state: "QC", label: "Quebec" },
      { npa: "450", state: "QC", label: "Quebec" },
      { npa: "468", state: "QC", label: "Quebec" },
      { npa: "514", state: "QC", label: "Quebec" },
      { npa: "579", state: "QC", label: "Quebec" },
      { npa: "581", state: "QC", label: "Quebec" },
      { npa: "819", state: "QC", label: "Quebec" },
      { npa: "873", state: "QC", label: "Quebec" },
      { npa: "306", state: "SK", label: "Saskatchewan" },
      { npa: "474", state: "SK", label: "Saskatchewan" },
      { npa: "639", state: "SK", label: "Saskatchewan" },
      { npa: "867", state: "YT", label: "Yukon" },
    ]
  },
  { 
    country: CountryCode.NORTHAMERICA, 
    TypePhoneNumber: TypePhoneNumber.TOLL_FREE,
    regions: [
      { npa: "800", state: "", label: "US & CAN" },
      { npa: "833", state: "", label: "US & CAN" },
      { npa: "844", state: "", label: "US & CAN" },
      { npa: "855", state: "", label: "US & CAN" }, 
      { npa: "866", state: "", label: "US & CAN" },
      { npa: "877", state: "", label: "US & CAN" },
      { npa: "888", state: "", label: "US & CAN" },
    ]
  },
];


export function getCountryRegions(country: CountryCode, type: TypePhoneNumber) {
  return mapRegions.find(region => region.country === country && region.TypePhoneNumber === type);
}

export function getDefaultAreaCode(country: CountryCode, type: TypePhoneNumber) {
  return getCountryRegions(country, type)?.regions[0].npa ?? "";
}

export function getNpa(country: CountryCode, type: TypePhoneNumber, npa: string) {
  return mapRegions.find(region => region.country === country && region.TypePhoneNumber === type)?.regions.find(region => region.npa === npa);
}

export function getLabelByPhoneNumber(phoneNumber: string) {
  const npa = phoneNumber.substring(0, 3);
  return mapRegions.find(region => region.regions.find(region => region.npa === npa))?.regions.find(region => region.npa === npa)?.label;
}

export function getCountryByNPA(npa: string) {
  return mapRegions.find(region => region.regions.find(region => region.npa === npa))?.country;
}

export function ConvertCountry(country: string) {
  switch (country.toUpperCase()) {
    case "US":
      return CountryCode.US;
    case "CA":
      return CountryCode.CA;
    default:
      return CountryCode.US;
  }
}

export function ConvertCountryPhone(country: string) {
  switch (country.toUpperCase()) {
    case "US":
      return CountryCode.US;
    case "CA":
      return CountryCode.CA;
    case "NORTHAMERICA":
      return CountryCode.NORTHAMERICA;
    default:
      return CountryCode.US;
  }
}

export function ConvertTypePhoneNumber(type: string) {
  switch (type.toUpperCase()) {
    case "LOCAL":
      return TypePhoneNumber.LOCAL;
    case "TOLL_FREE":
      return TypePhoneNumber.TOLL_FREE;
    default:
      return TypePhoneNumber.LOCAL;
  }
}