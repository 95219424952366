export function setSearchParam(key: string, newValue: string) {
    if (typeof window === 'undefined') {
        return;
    }
    const newUrl = new URL(window.location.href);
    if (newValue === '') {
        newUrl.searchParams.delete(key);
    } else {
        newUrl.searchParams.set(key, newValue);
    }
    window.history.replaceState(null, '', newUrl.toString());
}

export function decodeUrl(encodedUrl: string | null): string | null {
    if (encodedUrl === null) {
      return null;
    }
    try {
      return decodeURIComponent(encodedUrl);
    } catch (e) {
      console.error('Error decoding URL:', e);
      return null;
    }
  }